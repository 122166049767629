import { CameraAPI, CameraZoneAPI } from "@/api/camera.api";
import { FilterAPI, StatusAPI } from "@/api/filters.api";
import { BaseAPI, PluginAPI } from "@/api/base.api";


const state = {
    page_title: "CVC Client",

    plugin_list: [],

    camera_list: [],
    camera_zone_list: [],
    camera_zone_history_list: [],

    is_filter_loading: false,

    filter_list: [],  // TODO: Rename to filter_list
    filter_active_id: 0, // TODO: Rename to filter_active_id

    filter_active_status_list: [],
    filter_status_list: [],

    /* Закрыт ли текущий день */
    is_day_closed: false,
    /* Загрузка закрытия дня */
    is_day_closed_loading: false,
}

const getters = {
    pageTitle: state => state.page_title,

    pluginList: state => state.plugin_list,

    isDayClosed: state => state.is_day_closed,
    isDayClosedLoading: state => state.is_day_closed_loading,

    cameraList: state => state.camera_list,
    cameraListActive: state => state.camera_list.filter(t => t.active),

    cameraZoneList: state => state.camera_zone_list,
    cameraZoneListActive: state => state.camera_zone_list.filter(t => t.active),

    cameraZoneHistoryList: state => state.camera_zone_history_list,

    isFilterLoading: state => state.is_filter_loading,

    filterList: state => state.filter_list,
    filterActiveId: state => state.filter_active_id,

    filterActiveStatusList: state => state.filter_active_status_list,

    filterStatusList: state => state.filter_status_list,
};

const actions = {
    baseOptionsDate(context) {
        const dateCurrent = context.rootGetters['core/dateCurrent'];

        return {
            begin_time: dateCurrent.toFormat("yyyy-LL-dd HH:mm"),
            end_time: dateCurrent.plus({ days: 1 }).toFormat("yyyy-LL-dd HH:mm"),
        }
    },

    baseOptions(context) {
        const dateCurrent = context.rootGetters['core/dateCurrent'];

        let options = {
            begin_time: dateCurrent.toFormat("yyyy-LL-dd HH:mm"),
            end_time: dateCurrent.plus({ days: 1 }).toFormat("yyyy-LL-dd HH:mm"),
        }

        if (context.getters.filterActiveId) {
            options = {
                ...options,
                id_filter: context.getters.filterActiveId
            }
        }
        else {
            options = {
                ...options,
                id_camera: context.getters.cameraListActive.map((t) => t.id),
                id_camera_zone: context.getters.cameraZoneListActive.map((t) => t.id),
                id_event_type: context.rootGetters['event/eventTypeListActive'].map((t) => t.id),
            };
        }

        return options;
    },

    baseOptionsPeriod(context) {
        const dateCurrent = context.rootGetters['core/dateMonth'];

        let options = {
            begin_time: dateCurrent.toFormat("yyyy-LL-dd HH:mm"),
        };

        if (context.getters.filterActiveId) {
            options = {
                ...options,
                id_filter: context.getters.filterActiveId
            }
        }
        else {
            options = {
                ...options,
                id_camera: context.getters.cameraListActive.map((t) => t.id),
                id_camera_zone: context.getters.cameraZoneListActive.map((t) => t.id),
                id_event_type: context.rootGetters['event/eventTypeListActive'].map((t) => t.id),
            };
        }

        return options;
    },

    // region CameraList

    async fetchCameraList(context, { deleted }) {
        const options = {
            deleted: deleted
        }

        const response = await CameraAPI.cameraGetAll(options);

        response.data.forEach((value) => (value.active = false));
        context.commit('SET_CAMERA_LIST', response.data);
    },

    setCameraList(context, payload) {
        context.commit('SET_CAMERA_LIST', payload);
    },

    // endregion

    // region CameraZoneList

    async fetchCameraZoneList(context, { deleted }) {
        const options = {
            deleted: deleted
        }

        const response = await CameraZoneAPI.zoneGetAll(options);
        response.data.forEach((value) => (value.active = false));
        context.commit('SET_CAMERA_ZONE_LIST', response.data);
    },

    async fetchCameraZoneHistoryList(context) {
        const response = await CameraZoneAPI.historyGetAll();

        if (response.status !== 200) {
            console.log("fetchCameraZoneHistoryList: Something went wrong", response);
            return;
        }

        context.commit("SET_CAMERA_ZONE_HISTORY_LIST", response.data);
    },

    // endregion

    // region Filters

    async fetchFilterList(context, { deleted }) {
        let options = {
            deleted: deleted,
        };

        const response = await FilterAPI.filtersGetAll(options);
        context.commit("FILTER_LIST_SET", response.data);
    },

    filterActiveStatusListSet(context, payload) {
        context.commit('FILTER_ACTIVE_STATUS_LIST_SET', payload);
    },

    async fetchFilterStatusList(context, { deleted }) {
        let options = {
            deleted: deleted
        }

        const response = await StatusAPI.statusGetAll(options);
        context.commit("FILTER_STATUS_LIST_SET", response.data);
    },

    // endregion

    // region Plugins

    async fetchPluginList(context) {
        const response = await PluginAPI.pluginGetAll();

        if (response.status !== 200) {
            console.log("fetchPluginList something went wrong", response);
            return;
        }

        context.commit('PLUGIN_LIST_SET', response.data);
    },

    async pluginUpdate(context, {id, data}) {
        console.log('data', data)
        const response = await PluginAPI.pluginUpdate(id, data);

        console.log(response.data)
        if (response.status !== 200) {
            console.log("plugin update something went wrong", response);
            return;
        }

        context.commit('PLUGIN_UPDATE', {id: id, data: data});
    },

    // endregion

    async fetchConfiguration(context) {
        const data = await BaseAPI.configurationGet();
        context.commit("SET_PAGE_TITLE", data.title);

        // TODO:
        // if (payload.plugin_list) {
        //     context.commit("SET_PLUGIN_LIST", payload.plugin_list);
        // }
    },

    isDayClosedLoadingSet(context, value) {
        context.commit("IS_DAY_CLOSED_LOADING_SET", value);
    },

    isFilterLoading(context, value) {
        context.commit("IS_FILTER_LOADING", value);
    }
};

const mutations = {
    SET_PAGE_TITLE(state, value) {
        state.page_title = value;
    },

    PLUGIN_LIST_SET(state, payload) {
        state.plugin_list = [...payload];
    },

    PLUGIN_UPDATE(state, {id, data}) {
        const item = state.plugin_list.find(t => t.id === id);
        item.active = data.active;
    },

    SET_CAMERA_LIST(state, payload) {
        state.camera_list = payload;
    },

    SET_CAMERA_ZONE_LIST(state, payload) {
        state.camera_zone_list = payload;
    },

    SET_CAMERA_ZONE_HISTORY_LIST(state, payload) {
        state.camera_zone_history_list = payload;
    },

    FILTER_LIST_SET(state, payload) {
        state.filter_list = [...payload];

        if (state.filter_list.length)  {
            state.filter_active_id = state.filter_list[0].id;
        }
    },

    FILTER_ACTIVE_SET(state, value) {
        state.filter_active_id = value;
    },

    FILTER_ACTIVE_STATUS_LIST_SET(state, payload) {
        state.filter_active_status_list = [...payload];
    },

    FILTER_STATUS_LIST_SET(state, payload) {
        state.filter_status_list = [...payload];
    },

    SET_IS_DAY_CLOSED(state, payload) {
        state.is_day_closed = payload;
    },

    IS_DAY_CLOSED_LOADING_SET(state, value) {
        state.is_day_closed_loading = value;
    },

    IS_FILTER_LOADING(state, value) {
        state.is_filter_loading = value;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
