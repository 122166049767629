import API, {APIReturn} from "@/api";

const RESOURCE = '/base';

interface ISetting {
    id: number,
    system_name: string,
    name: string,
    description: string
    type: string,
    value: string
}

// interface ISettingCreate {
//     system_name: string,
//     name: string,
//     description: string
//     type: string,
// }

interface ISettingUpdate {
    name: string,
    description: string
    type: string,
    value: string
}

export const SettingsAPI = {

    settingsGetAll: async (): APIReturn<Array<ISetting>> => {
        // [GET] v1/base/settings/
        return await API.get<Array<ISetting>>(`${RESOURCE}/settings/`);
    },

    // settingsCreate: async (data: ISettingCreate): APIReturn<ISetting> => {
    //     // [POST] v1/base/settings/
    //     return await API.post<ISetting>(`${RESOURCE}/settings/`, data);
    // },

    settingsUpdate: async (id: number, data: ISettingUpdate): APIReturn<ISetting> => {
        // [PUT] v1/base/settings/:id
        return await API.put<ISetting>(`${RESOURCE}/settings/${id}/`, data);
    },

    // settingsDelete: async (id: number): APIReturn<string> => {
    //     // [DELETE] v1/base/settings/:id
    //     return await API.delete<string>(`${RESOURCE}/settings/${id}/`);
    // }
}

interface IAtlasSetting {
    id: number,
    system_name: string,
    name: string,
    description: string,
    default: string,
    types: string,
    value: string
}

interface IAtlasSettingUpdate {
    description: string,
    default: string,
    value: string
}

export const AtlasSettingsAPI = {

    settingsGetAll: async (): APIReturn<Array<IAtlasSetting>> => {
        // [GET] v1/base/settings/
        return await API.get<Array<IAtlasSetting>>(`${RESOURCE}/settings/atlas/`);
    },

    settingsUpdate: async (id: number, data: IAtlasSettingUpdate): APIReturn<IAtlasSetting> => {
        // [PUT] /v1/base/modules/:id/
        return await API.put<IAtlasSetting>(`${RESOURCE}/settings/atlas/${id}/`, data);
    }

}


interface IPlugin {
    id: number,
    system_name: string,
    name: string,
    active: boolean,
    settings: IPluginSetting[]
}

interface IPluginUpdate {
    active: boolean,
    settings: IPluginSettingUpdate[]
}

interface IPluginSetting {
    id: number,
    system_name: string,
    name: string,
    description: string,
    type: string,
    value: any
}

interface IPluginSettingUpdate {
    system_name: string,
    value: any
}

export const PluginAPI = {
    pluginGetAll: async (): APIReturn<Array<IPlugin>> => {
        // [GET] /v1/base/modules/
        return await API.get<Array<IPlugin>>(`${RESOURCE}/modules/`);
    },

    pluginUpdate: async (id: number, data: IPluginUpdate): APIReturn<IPlugin> => {
        // [PUT] /v1/base/modules/:id/
        return await API.put<IPlugin>(`${RESOURCE}/modules/${id}/`, data);
    }
}

interface IColor {
    id: number,
    system_name: string,
    name: string,
    color: string
}

interface IConfig {
    title: string
}

export const BaseAPI = {
    colorsGetAll: async (): Promise<Array<IColor>> => {
        // Получение информации о цветах в системе
        // [GET] /v1/base/colors/
        let result: Array<IColor> = [];

        const response = await API.get<Array<IColor>>(`${RESOURCE}/colors/`);

        switch (response.status) {
            case 200:
                result = response.data;
                break;
            default:
                break;
        }

        return result;
    },

    configurationGet: async (): Promise<IConfig> => {
        // Получение конфигурации клиента
        // [GET] /v1/base/configuration/
        let result: IConfig = {
            title: "CVC"
        };

        const response = await API.get<IConfig>(`${RESOURCE}/configuration/`);

        switch (response.status) {
            case 200:
                result = response.data;
                break;
            default:
                console.log("configurationGet: Something went wrong", response);
                break;
        }

        return result;
    }
}
