const ID_TOKEN_KEY = 'id_token';

export const getTokenLocal = () => window.localStorage.getItem(ID_TOKEN_KEY);
export const saveTokenLocal = (value) => window.localStorage.setItem(ID_TOKEN_KEY, value);
export const removeTokenLocal = () => window.localStorage.removeItem(ID_TOKEN_KEY);

// export const getTokenSession = () => window.sessionStorage.getItem(ID_TOKEN_KEY);
// export const saveTokenSession = (token) => window.sessionStorage.setItem(ID_TOKEN_KEY, token);
// export const removeTokenSession = () => window.sessionStorage.removeItem(ID_TOKEN_KEY);


const VIDEO_VOLUME = 'video_volume';

export const getVideoVolumeLocal = () => window.localStorage.getItem(VIDEO_VOLUME);
export const saveVideoVolumeLocal = (value) => window.localStorage.setItem(VIDEO_VOLUME, value);
export const removeVideoVolumeLocal = () => window.localStorage.removeItem(VIDEO_VOLUME);
