<template>
    <div class="flex flex-row w-full h-full">
        <div class="flex flex-col w-full pl-20 mt-4">
            <!-- Header -->
            <div class="flex flex-row items-start justify-between">
                <div class="flex flex-col text-second font-bold">
                    <div class="text-lg px-2">
                        Настройки
                    </div>
                </div>
            </div>

            <!-- Content -->
            <div class="flex flex-row h-full overflow-hidden w-full">
                <!-- Menu -->
                <div class="flex flex-col justify-between settings-navbar no-selection mr-2">
                    <div class="flex flex-col">
                        <div
                            v-for="(item, index) in menu_list"
                            :key="index"
                        >
                            <div
                                class="mt-2 flex flex-row uppercase text-header font-bold text-sm leading-4 px-2 py-1"
                                v-if="item.type === 'header' && item.is_visible"
                            >
                                {{ item.name }}
                            </div>

                            <div
                                class="flex flex-row cursor-pointer pr-2 pl-4 py-1 menu-item rounded text-base"
                                :class="{'menu-item-active': selected_menu_item === index }"
                                v-if="item.type === 'item' && item.is_visible"
                                @click="setView(item.view, index)"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col">

                        <Divider color="item-background"/>

                        <div
                            class="flex flex-row text-xs items-center justify-center text-second font-bold mt-4 px-2">
                            Version: {{ version }}
                        </div>
                    </div>
                </div>

                <Divider vertical color="item-background"/>

                <!-- View -->
                <div class="flex flex-row w-full px-3 mb-2 overflow-y-auto overflow-x-hidden">
                    <component v-if="view" v-bind:is="view"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { MENU_LIST } from "@/views/settings/helper";
import Divider from "@/components/base/Divider";

export default {
    name: "SettingsView",
    components: {Divider},
    data: () => ({
        menu_list: MENU_LIST,
        view: '',

        selected_menu_item: 0
    }),

    computed: {
        ...mapGetters("auth", [ "userInfo"]),
        ...mapGetters("core", [ "version"]),
    },

    async created() {
        const menus = this.menu_list.filter(t => t.visible === 'admin');
        const names = ['n.kushnarenko', 'cvc', 'exrofol'];  // TODO: @RoleRule Remove when role rule system is there

        menus.forEach(value => this.$set(value, 'is_visible', names.includes(this.userInfo.login)));

        await this.$store.dispatch("core/fetchColors");
    },

    mounted() {
        const first_index = this.menu_list.findIndex(t => t.type === 'item')
        const first_view = this.menu_list[first_index].view;

        this.setView(first_view, first_index);
    },

    methods: {
        async setView(view, index) {
            this.selected_menu_item = index;
            this.view = view;
        }
    },
};
</script>

<style scoped>

.text-header {
    color: #8e9297;
}

.settings-navbar {
    min-width: 12rem;
    width: 12rem;
    max-width: 12rem;
}

.menu-item {
    color: #dcddde;
}

.menu-item:hover,
.menu-item-active {
    background-color: #303131;
    color: #FFFFFF;
}
</style>
