<template>
    <div class="flex flex-row">
        <div class="flex flex-col mr-1 filter-col">
            <div
                class="flex flex-row items-center justify-end bg-item mb-1 p-1"
            >
                <div
                    class="inline-flex items-center justify-center py-1 px-2 cursor-pointer icon"
                    :title="iconClosed.title"
                    v-if="this.$can('event.close_set')"
                    @click="closeEvents"
                >
                    <v-icon size="18" :color="iconClosed.color">{{ iconClosed.icon }}</v-icon>
                </div>
            </div>

            <DatePicker
                class="pb-1"
                :date="dateCurrent"
                :month="dateMonth"
                :time_period="dataTimePeriod"
                @onDateChange="onDateUpdate"
                @onMonthChange="onMonthUpdate"
            />

            <Divider v-if="!userHasAdvancedAccess"/>

            <div
                class="flex flex-col w-full event-filters overflow-hidden pt-1"
            >
                <!-- FILTER TABS -->
                <div
                    class="flex flex-row mb-1 text-sm no-selection mt-1"
                    v-if="userHasAdvancedAccess"
                >
                    <div
                        v-for="item in filter_tabs"
                        :key="item.id"
                        class="px-2 py-1 flex-auto bg-item cursor-pointer filter-tab"
                        :class="{
                            'filter-tab-selected':
                                item.id === filter_tab_selected,
                        }"
                        @click="selectFilterTab(item.id)"
                    >
                        {{ item.name }}
                    </div>
                </div>

                <div
                    class="flex my-5"
                    v-if="isFilterLoading"
                >
                    <LoadingSpinner
                        :size="36"
                        text-size="base"
                    >
                        Загрузка фильтров ...
                    </LoadingSpinner>
                </div>

                <!-- SMART FILTERS -->
                <div
                    class="flex flex-col overflow-y-auto h-full no-selection"
                    v-if="filter_tab_selected === 1 && !isFilterLoading"
                >
                    <FilterTreeView
                        :items="filterList"
                    />
                </div>

                <!-- ADVANCED -->
                <div
                    class="flex flex-col overflow-y-auto h-full"
                    id="eventFilter"
                    v-if="filter_tab_selected === 2 && !isFilterLoading && userHasAdvancedAccess"
                >
                    <CheckboxGroup
                        class="mb-2"
                        :name="'Типы событий'"
                        :item_list="eventTypeList"
                        @toggleItem="updateAdvanced"
                    />

                    <CheckboxGroup
                        class="mb-2"
                        :name="'Камеры'"
                        :item_list="cameraList"
                        @toggleItem="updateAdvanced"
                    />

                    <CheckboxGroup
                        class="mb-2"
                        :name="'Камеры зоны'"
                        :item_list="cameraListWithCameras"
                        @toggleItem="updateAdvanced"
                    />

                    <CheckboxGroup
                        :name="'Объекты'"
                        :item_list="objectTypeList"
                    />
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full relative">
            <ClosingDayOverlay/>
            <router-view/>
        </div>

        <EventModal/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { EventAPI } from "@/api/event.api";

import { EventBus, NEED_TO_FETCH_DATA } from "@/utils/event-bus.js";
import { flatArrayWithChildren } from "@/utils/array";

import Notification from "@/mixins/notification.js";

import CheckboxGroup from "@/components/CheckboxGroup.vue";
import DatePicker from "@/components/calendar/DatePicker.vue";
import EventModal from "@/components/events/EventModal.vue";
import FilterTreeView from "@/components/core/FilterTreeView.vue";
import ClosingDayOverlay from "@/components/base/ClosingDayOverlay";
import LoadingSpinner from "@/components/core/LoadingSpinner";
import Divider from "@/components/base/Divider";
import { timeFromISO } from "@/utils/time";

const filterTabs = [
    {
        id: 1,
        name: "Смарты",
    },
    {
        id: 2,
        name: "Advanced",
    },
];

export default {
    name: "Main",

    mixins: [Notification],

    components: {
        Divider,
        LoadingSpinner,
        ClosingDayOverlay,
        CheckboxGroup,
        DatePicker,
        EventModal,
        FilterTreeView
    },

    data: () => ({
        filter_tabs: [],
        filter_tab_selected: 1,
    }),

    async created() {
        // Date picker stuff
        {
            const current_date = new Date().toISOString().substr(0, 10); // TODO: need to change this deprecated shit

            await this.onDateUpdate(current_date);
            await this.onMonthUpdate(current_date);

        }

        this.filter_tabs = filterTabs;

        await this.$store.dispatch("base/isFilterLoading", true);

        await this.$store.dispatch("core/fetchColors");

        await this.$store.dispatch('base/fetchCameraList', {deleted: false});
        await this.$store.dispatch('base/fetchCameraZoneList', {deleted: false});
        await this.$store.dispatch('base/fetchCameraZoneHistoryList');

        await this.$store.dispatch('event/fetchEventTypeList');

        await this.$store.dispatch('base/fetchFilterStatusList', {deleted: false});
        await this.$store.dispatch('base/fetchFilterList', {deleted: false});

        await this.$store.dispatch("base/isFilterLoading", false);

        await this.$store.dispatch("video/fetchObjectTypeList");
    },

    computed: {
        ...mapGetters("base", [
            "isDayClosed",
            "cameraList",
            "cameraListActive",
            "cameraZoneList",
            "cameraZoneListActive",
            "filterList",
            "filterActiveId",
            "filterStatusList",
            "isFilterLoading"
        ]),

        ...mapGetters("core", ["dateCurrent", "dateMonth", "dataTimePeriod", "colors"]),

        ...mapGetters("event", ["eventTypeList", "eventTypeListActive"]),

        ...mapGetters("video", ["objectTypeList", "objectTypeListActive"]),

        iconClosed() {
            return {
                icon: this.isDayClosed ? 'fas fa-lock' : 'fas fa-unlock',
                title: this.isDayClosed ? 'День закрыт' : 'Закрыть день',
                color: this.isDayClosed ? '#dc3545' : '#64ff6499',
            }
        },

        cameraListWithCameras() {
            if (!this.cameraListActive.length) return this.cameraZoneList;

            const ids = this.cameraListActive.map(t => t.id);

            this.cameraZoneList.filter(t => !ids.includes(t.id_camera)).forEach(t => t.active = false);

            return this.cameraZoneList.filter(t => ids.includes(t.id_camera));
        },

        userHasAdvancedAccess() {
            return this.$can('filter.advanced_access');
        }
    },

    watch: {
        async filterActiveId(id_smart) {
            if (id_smart > 0) {
                await this.selectSmart(id_smart);
            }
        }
    },

    methods: {
        // Date picker stuff
        async onDateUpdate(value) {
            await this.$store.dispatch('core/setDateCurrent', timeFromISO(value));
        },

        async onMonthUpdate(value) {
            await this.$store.dispatch('core/setDateMonth', timeFromISO(value));
        },

        // Update stuff
        async updateAdvanced() {
            if (this.filterActiveId) {
                this.showInfoNotification(
                    "Смарт фильтры отключены",
                    'Вы выбрали фильтр из вкладки "Advanced"\nТекущий Смарт фильтр отключён',
                    10000
                );

                this.$store.commit('base/FILTER_ACTIVE_SET', 0);
            }

            EventBus.$emit(NEED_TO_FETCH_DATA);
        },

        selectFilterTab(id_tab) {
            if (this.filter_tab_selected === id_tab) return;

            this.filter_tab_selected = id_tab;
        },

        async selectSmart(id_filter) {
            let flat_smart = flatArrayWithChildren(this.filterList);
            let current_filter = flat_smart.find(t => t.id === id_filter);

            // TODO: :Temporary Это невежество требуется удалить когда система будет
            // адекватно воспринимать модули и их параметры.
            switch (current_filter.plugin) {
                // Events
                case 1:
                    if (this.$router.currentRoute.name !== 'event-view')
                        await this.$router.push({name: "event-view"});
                    break;

                // Auto
                case 2:
                    if (this.$router.currentRoute.name !== 'auto-view')
                        await this.$router.push({name: "auto-view"});
                    break;

                // People
                case 3:
                    if (this.$router.currentRoute.name !== 'people-view')
                        await this.$router.push({name: "people-view"});
                    break;

                // KKO
                case 4:
                    if (this.$router.currentRoute.name !== 'kko-view')
                        await this.$router.push({name: "kko-view"});
                    break;

                // Стоматология
                case 5:
                    if (this.$router.currentRoute.name !== 'stoma-view')
                        await this.$router.push({name: "stoma-view"});
                    break;

                // SkiPass
                case 6:
                    if (this.$router.currentRoute.name !== 'ski-view')
                        await this.$router.push({name: "ski-view"});
                    break;

                // SkiPass
                case 7:
                    if (this.$router.currentRoute.name !== 'ozon-view')
                        await this.$router.push({name: "ozon-view"});
                    break;

                case 8:
                    if (this.$router.currentRoute.name !== 'shift-view')
                        await this.$router.push({name: "shift-view"});
                    break;

                default:
                    if (this.$router.currentRoute.name !== 'event-view')
                        await this.$router.push({name: "event-view"});
                    break;
            }

            // TODO: Когда появится возможность скрывать Advanced, то нужно учитывать
            //  что всё что находится снизу не нужно будет делать, так как у пользователя
            //  не будет возможности и изменить

            // Set up event list
            this.eventTypeListActive.forEach((value) => (value.active = false));
            if (current_filter.id_event_type) {
                this.eventTypeList
                    .filter((x) => current_filter.id_event_type.includes(x.id))
                    .forEach((value) => (value.active = true));
            }

            // Set up id_camera
            this.cameraListActive.forEach((value) => (value.active = false));
            if (current_filter.id_camera) {
                this.cameraList
                    .filter((x) => current_filter.id_camera.includes(x.id))
                    .forEach((value) => (value.active = true));
            }

            // Set up id_camera_zone
            this.cameraZoneListActive.forEach(
                (value) => (value.active = false)
            );
            if (current_filter.id_camera_zone) {
                this.cameraZoneList
                    .filter((x) => current_filter.id_camera_zone.includes(x.id))
                    .forEach((value) => (value.active = true));
            }

            // Устанавливаем id_object_type при выборе смарт фильтра.
            // Если обхекты указаны смарт фильтры, то проходимся по
            // списку и выставляем необходимым объектам флаг.
            // Если же они были не указаны, то ставим флаг для всех которые
            // не указаны в данный момент.
            if (current_filter.id_object_type) {
                this.objectTypeListActive.forEach(
                    (value) => (value.active = false)
                );
                this.objectTypeList
                    .filter((x) => current_filter.id_object_type.includes(x.id))
                    .forEach((value) => (value.active = true));
            } else {
                this.objectTypeList
                    .filter((x) => !x.active)
                    .forEach((value) => (value.active = true));
            }

            // Обновляем список текущих статусов для выбранного фильтра

            if (current_filter.ids_status) {
                const temp_statuses = this.filterStatusList.filter(t => current_filter.ids_status.includes(t.id) && !t.deleted && t.id_status_image);

                // Сборка цветов и статусов для рендера на стороне событий
                const finished_status_list = [];

                temp_statuses.forEach(value => {
                    if (!value.icon) {
                        console.log(`Filter ${value.id} - ${value.name} doesn't have icon for some reason.\n${value} `);
                        return;
                    }

                    const cur_color = this.colors.find(t => t.id === value.icon.id_color) || "#FFFFFF";

                    finished_status_list.push({
                        id: value.id,
                        name: value.name,
                        icon: value.icon.css_class,
                        icon_size: value.icon.size,
                        icon_color: cur_color.color,
                    })
                });

                await this.$store.dispatch('base/filterActiveStatusListSet', finished_status_list);
            } else {
                await this.$store.dispatch('base/filterActiveStatusListSet', []);
            }

            EventBus.$emit(NEED_TO_FETCH_DATA);
        },

        async confirmCloseEvents() {
            await this.$store.dispatch('base/isDayClosedLoadingSet', true);
            let begin_time = this.dateCurrent.toFormat("yyyy-LL-dd HH:mm");
            let end_time = this.dateCurrent
                .plus({days: 1})
                .toFormat("yyyy-LL-dd HH:mm");

            let data = {
                id_filter: this.filterActiveId,
                begin_time: begin_time,
                end_time: end_time,
            };

            await EventAPI.closeDay(data).then((response) => {
                this.$store.dispatch('base/isDayClosedLoadingSet', false);

                if (response.status === 403) {
                    this.showErrorNotification(
                        "Невозможно закрыть день!",
                        response.data.detail,
                        15000
                    );
                    return;
                }

                EventBus.$emit(NEED_TO_FETCH_DATA);
            });
        },

        async closeEvents() {
            // TODO: Добавить логику can на всякий случай.

            if (this.isDayClosed) {
                this.showInfoNotification(
                    "Уже закрыто",
                    "День уже закрыт. Выберите другой день или смарт фильтр.",
                    10000
                );
                return;
            }

            if (!this.filterActiveId) {
                this.showInfoNotification(
                    "Нельзя закрыть день",
                    "Нельзя закрыть день, если не выбран смарт фильтр",
                    10000
                );

                return;
            }

            await this.$store.dispatch("core/showConfirmDialog", {
                title: "Закрытие дня",
                message:
                    "Вы действительно хотите закрыть события? Это действие не обратимо! События нельзя будет открыть!",
                onConfirm: this.confirmCloseEvents,
                onCancel: null,
            });
        },
    },
};
</script>


<style scoped>
.event-filters {
    height: calc(100vh - 30px - 200px);
    max-height: calc(100vh - 30px - 200px);
}

/* SCROLLBAR */
#eventFilter::-webkit-scrollbar {
    width: 0;
}

/* Filter tabs */
.filter-tab:first-child {
    margin-right: 0.125rem;
}

.filter-tab:last-child {
    margin-left: 0.125rem;
}

.filter-tab-selected {
    border-bottom: 2px solid var(--blue);
}

.filter-col {
    min-width: 260px;
    max-width: 260px;
    width: 260px;
}

.icon:hover {
    outline: 1px solid var(--dark-gray);
}


</style>
