import { ObjectAPI, VideoAPI } from "@/api/video.api";

const state = {
    /** Останаливать ли видео в конце события */
    flag_stop_at_event_end: true,

    /** Показывать ли Трекер при отрисовке объектов */
    flag_show_tracker: false,

    /** Автоматический старт видео */
    flag_auto_start_video: true,

    // Показывать зону для события
    flag_show_zone_for_event: true,

    video_not_found: false,
    video_is_loading: false,

    video_id_camera: 0,
    video_current: {},

    object_type_list: [],
    object_list: [],

    archive_list: [],
};

const getters = {
    // Flags
    flag_stop_at_event_end: (state) => state.flag_stop_at_event_end, // TODO: rename to camelcase
    flagShowTracker: (state) => state.flag_show_tracker,
    flagAutoStartVideo: (state) => state.flag_auto_start_video,
    flagShowZoneForEvent: (state) => state.flag_show_zone_for_event,

    videoNotFound: (state) => state.video_not_found,
    videoIsLoading: (state) => state.video_is_loading,

    // Video stuff
    // TODO: Оно здесь лишнее. Нужно перенести обратно в плеер
    videoCurrent: (state) => state.video_current,
    videoIdCamera: (state) => state.video_id_camera,

    objectList: (state) => state.object_list,

    objectTypeList: (state) => state.object_type_list,
    objectTypeListActive: (state) =>
        state.object_type_list.filter((t) => t.active),

    archiveList: (state) => state.archive_list,
};

const actions = {
    async getVideoList(context, options) {
        context.state.video_is_loading = true;
        context.state.video_not_found = false;

        await VideoAPI.videoGet(options).then((response) => {
            context.state.video_is_loading = false;

            if (response.status === 404) {
                context.commit("VIDEO_CURRENT_CLEAR");

                context.state.video_not_found = true;
                return;
            }
            context.commit("VIDEO_CURRENT_SET", response.data);
        });
    },

    async getObjectList(context, options) {
        await ObjectAPI.objectsGetAll(options).then((response) => {
            context.commit("SET_OBJECT_LIST", response.data);
        });
    },

    clearObjectList(context) {
        context.commit("CLEAR_OBJECT_LIST");
    },

    async fetchObjectTypeList(context) {
        await ObjectAPI.objectTypeGetAll({ hide: false }).then((response) => {
            response.data.forEach((value) => (value.active = true));
            context.commit("SET_OBJECT_TYPE_LIST", response.data);
        });
    },

    setVideoIdCamera(context, value) {
        if (context.getters.videoIdCamera === value) {
            return;
        }
        context.commit("SET_VIDEO_ID_CAMERA", value);
    },

    async getArchiveList(context, options) {
        await VideoAPI.archiveGet(options).then((response) => {
            context.commit("SET_ARCHIVE_LIST", response.data);
        });
    },

    clearArchiveList(context) {
        context.commit("CLEAR_ARCHIVE_LIST");
    },

    setFlagStopAtEventEnd(context, value) {
        context.commit("SET_FLAG_STOP_AT_EVENT_END", value);
    },

    setFlagShowTracker(context, value) {
        context.commit("SET_FLAG_SHOW_TRACKER", value);
    },

    setFlagAutoStartVideo(context, value) {
        context.commit("SET_FLAG_AUTO_START_VIDEO", value);
    },

    setFlagShowZoneForEvent(context, value) {
        context.commit("SET_FLAG_SHOW_ZONE_FOR_EVENT", value);
    },
};

const mutations = {
    VIDEO_CURRENT_SET(state, payload) {
        state.video_current = { ...payload };
    },

    VIDEO_CURRENT_CLEAR(state) {
        state.video_current = {};
    },

    SET_OBJECT_LIST(state, payload) {
        // :Not Finished
        // Возможно следует проверять уникальность
        // object_time, если вероятность что
        // объекты будут дублироваться
        state.object_list.push(...payload);
    },

    OBJECT_LIST_TRUNCATE_START(state, object_time) {
        state.object_list = state.object_list.filter(
            (t) => t.object_time > object_time
        );
    },

    OBJECT_LIST_TRUNCATE_END(state, object_time) {
        state.object_list = state.object_list.filter(
            (t) => t.object_time < object_time
        );
    },

    CLEAR_OBJECT_LIST(state) {
        state.object_list = [];
    },

    SET_OBJECT_TYPE_LIST(state, payload) {
        state.object_type_list = [...payload];
    },

    SET_VIDEO_ID_CAMERA(state, payload) {
        state.video_id_camera = payload;
    },

    SET_ARCHIVE_LIST(state, payload) {
        state.archive_list = [...payload];
    },

    CLEAR_ARCHIVE_LIST(state) {
        state.archive_list = [];
    },

    SET_FLAG_STOP_AT_EVENT_END(state, value) {
        state.flag_stop_at_event_end = value;
    },

    SET_FLAG_SHOW_TRACKER(state, value) {
        state.flag_show_tracker = value;
    },

    SET_FLAG_AUTO_START_VIDEO(state, value) {
        state.flag_auto_start_video = value;
    },

    SET_FLAG_SHOW_ZONE_FOR_EVENT(state, value) {
        state.flag_show_zone_for_event = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
