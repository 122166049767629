import { arrayIsEqual } from "@/utils/array";
import { BaseAPI } from "@/api/base.api";

const state = {
    /** Текущая версия клиентского приложения */
    version: "1.15",

    /** Требуется ли нам обновить клиентское приложение */
    is_update: false,

    date_current: "",
    date_month: "",
    data_time_period: [], // Массив дней в котором присутствуют необходимые нам данные

    notificationQueue: [],
    colors: [],

    confirm_dialog: {
        show: false,
        title: "",
        message: "",
        onConfirm: null,
        onCancel: null,
    },
};

const getters = {
    isUpdate: (state) => state.is_update,
    version: (state) => state.version,

    dateCurrent: (state) => state.date_current,
    dateMonth: (state) => state.date_month,
    dataTimePeriod: (state) => state.data_time_period,

    notificationQueue: (state) => state.notificationQueue,

    colors: (state) => state.colors,
    colorsGet: (state) => (id_color) =>
        state.colors.find((t) => t.id === id_color),

    confirmDialog: (state) => state.confirm_dialog,
};

const actions = {
    setDateCurrent(context, payload) {
        context.commit("SET_DATE_CURRENT", payload);
    },

    setDateMonth(context, payload) {
        context.commit("SET_DATE_MONTH", payload);
    },

    setDataTimePeriod(context, payload) {
        const isEqual = arrayIsEqual(context.getters.dataTimePeriod, payload);
        if (!isEqual) {
            context.commit("SET_DATA_TIME_PERIOD", payload);
        }
    },

    clearDataTimePeriod(context) {
        context.commit("CLEAR_DATA_TIME_PERIOD");
    },

    addNotificationInfo(context, { header, message, timeout = 5000 }) {
        context.dispatch("addNotification", {
            header,
            message,
            timeout,
            type: "info",
        });
    },

    addNotificationError(context, { header, message, timeout = 5000 }) {
        context.dispatch("addNotification", {
            header,
            message,
            timeout,
            type: "error",
        });
    },

    addNotification(
        context,
        { header, message, timeout = 5000, type = "info" }
    ) {
        context.commit("ADD_NOTIFICATION", { header, message, timeout, type });
    },

    async fetchColors(context) {
        const data = await BaseAPI.colorsGetAll();
        context.commit("COLORS_SET", data);
    },

    showConfirmDialog(context, { title, message, onConfirm, onCancel }) {
        context.commit("SHOW_CONFIRM_DIALOG", {
            title,
            message,
            onConfirm,
            onCancel,
        });
    },

    closeConfirmDialog(context) {
        context.commit("CLOSE_CONFIRM_DIALOG");
    },

    setUpdate(context, value) {
        context.commit("SET_UPDATE", value);
    },
};

const mutations = {
    SET_UPDATE(state, value) {
        state.is_update = value;
    },

    SET_DATE_CURRENT(state, payload) {
        state.date_current = payload;
    },

    SET_DATE_MONTH(state, payload) {
        state.date_month = payload;
    },

    SET_DATA_TIME_PERIOD(state, payload) {
        state.data_time_period = [...payload];
    },

    CLEAR_DATA_TIME_PERIOD(state) {
        state.data_time_period = [];
    },

    ADD_NOTIFICATION(state, payload) {
        state.notificationQueue.push(payload);
    },

    COLORS_SET(state, payload) {
        state.colors = payload;
    },

    SHOW_CONFIRM_DIALOG(state, { title, message, onConfirm, onCancel }) {
        state.confirm_dialog.show = true;
        state.confirm_dialog.title = title;
        state.confirm_dialog.message = message;
        state.confirm_dialog.onConfirm = onConfirm;
        state.confirm_dialog.onCancel = onCancel;
    },

    CLOSE_CONFIRM_DIALOG(state) {
        state.confirm_dialog.show = false;
        state.confirm_dialog.title = "";
        state.confirm_dialog.message = "";
        state.confirm_dialog.onConfirm = null;
        state.confirm_dialog.onCancel = null;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
