<template>
    <nav class="navbar flex items-center justify-between text-white">
        <div class="flex flex-row items-center my-1">
            <div class="mx-5 my-2">
                <img src="../assets/logo.svg" width="30" height="15" alt />
            </div>

            <router-link
                v-for="link in links"
                :key="link.id"
                class="nav-link py-0.5 px-3 ml-1 rounded"
                active-class="active"
                :to="link.url"
                :exact="link.exact"
                v-show="link.active"
                v-tooltip.bottom="link.name"
            >
<!--
                <v-tooltip bottom color="#242526">
                    <template v-slot:activator="{ on, attrs }">
                    -->
                        <v-icon
                            size="14"
                            class="icon-pop"
                            color="rgb(255,255,255,0.8)"
                            >{{ link.icon }}</v-icon>
                    <!--</template>
                    <span class="text-white">{{ link.name }}</span>
                </v-tooltip>-->
            </router-link>
        </div>

        <div class="flex flex-row items-center">
            <div>
                <v-menu v-model="profileMenu" offset-y z-index="100">
                    <template v-slot:activator="{ on }">
                        <div
                            class="flex p-1 mr-2 items-center cursor-pointer profile-button rounded"
                            v-on="on"
                        >
                            <v-avatar size="24" :color="userCurrent.color">
                                <span class="text-xs">{{ userCurrent.initials }}</span>
                            </v-avatar>
                        </div>
                    </template>

                    <div class="flex flex-col bg-item w-56">
                        <div
                            class="flex flex-row items-center px-4 pt-4 pb-3 border-bottom"
                        >
                            <v-avatar size="32" :color="userCurrent.color">
                                <span>{{ userCurrent.initials }}</span>
                            </v-avatar>

                            <div class="ml-2">{{ userCurrent.login }}</div>
                        </div>

                        <div class="flex flex-col">
                            <div
                                class="menu-link px-4 py-2 mt-1 flex flex-row items-center cursor-pointer"
                                @click="openSettings"
                            >
                                <v-icon size="18" color="rgb(255,255,255,0.4)">
                                    fas fa-cog
                                </v-icon>
                                <div class="ml-2 text-second">Настройки</div>
                            </div>

                            <router-link
                                to="/logout"
                                v-show="true"
                                class="menu-link px-4 py-2 mt-1 flex flex-row items-center"
                            >
                                <v-icon size="18" color="rgb(255,255,255,0.4)">
                                    fas fa-sign-out-alt
                                </v-icon>
                                <div class="ml-2 text-second">Выход</div>
                            </router-link>
                        </div>
                    </div>
                </v-menu>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';

import { getInitials } from "@/utils/user";

import Tooltip from '@/directives/tooltip';

export default {
    data: () => ({
        profileMenu: false,
        links: []
    }),


    directives: {
        tooltip: Tooltip
    },

    computed: {
        ...mapGetters('auth', ["userInfo"]),
        ...mapGetters('base', ["pluginList"]),

        userCurrent() {
            const info = getInitials(this.userInfo.login);

            return {
                ...this.userInfo,
                ...info
            }
        }
    },

    watch: {
        pluginList: {
            handler() {
                this.buildPluginLinks();
            },
            deep: true
        }
    },

    async created() {
        await this.$store.dispatch('base/fetchPluginList');
    },

    methods: {
        buildPluginLinks() {
            this.links = [];

            let temp_links = [];

            for (let plugin of this.pluginList) {
                let icon = "";
                let url = { name: "" }
                let exact = false;

                switch (plugin.system_name) {
                    case 'plugin_event':
                        icon = "fas fa-indent fa-fw";
                        url = { name: "event-view" };
                        exact = true;
                        break;

                    case "plugin_auto":
                        icon = "fas fa-car fa-fw";
                        url = { name: "auto-view" };
                        break;

                    case "plugin_people":
                        icon = "fas fa-user-alt fa-fw";
                        url = { name: "people-view" };
                        break;

                    case "plugin_kko":
                        icon = "fas fa-cash-register fa-fw";
                        url = { name: "kko-view" };
                        break;

                    case "plugin_d4w":
                        icon = "fas fa-teeth fa-fw";
                        url = { name: "stoma-view" };
                        break;

                    case "plugin_skipass":
                        icon = "fas fa-skiing fa-fw";
                        url = { name: "ski-view" };
                        break;

                    case "plugin_manufacturing":
                        icon = "fas fa-regular fa-calendar";
                        url = { name:"shift-view" };
                        break;

                    case "plugin_ozon":
                        icon = "fas fa-dolly fa-fw";
                        url = { name: "ozon-view" };
                        break;
                }

                const data = {
                    id: plugin.id,
                    name: plugin.name,
                    icon: icon,
                    exact: exact,
                    url: url,
                    active: plugin.active
                }

                temp_links.push(data);
            }

            this.links = [...temp_links];
        },

        openSettings() {
            this.$router.push({name: 'settings-view'});
        },
    },
};
</script>

<style>
.navbar {
    background-color: var(--item-background);
    min-height: 30px;
    height: 30px;
    max-height: 30px;
}

.nav-link:hover,
.active {
    background-color: rgba(255, 255, 255, 0.1);
}

.profile-button:hover {
    background-color: var(--white-alpha-01);
}

.menu-link:hover {
    background-color: var(--white-alpha-01);
}

.version {
    border-top: solid 1px;
}

.nav-link:hover .icon-pop,
.active .icon-pop {
    transform: scale(1.1);
}
</style>
