import API from '@/api';
import router from '@/router/index';
import UserAPI from '@/api/user.api';

import { saveTokenLocal, getTokenLocal, removeTokenLocal } from '@/storage/token.storage';

const state = {
    token: null,
    is_log_in: false,
    // Временно комментируем дефолтные параметры, потому что при разработке они удаляются.
    userInfo: {
        // id: 0,
        // login: "",
        // list_access: [],
        // is_super_user: false
    },

    flag_incorrect_login_data: false
}

const getters = {
    token: s => s.token,
    isLogIn: s => s.is_log_in,
    userInfo: state => state.userInfo,

    flagIncorrectLoginData: state => state.flag_incorrect_login_data,
};

const actions = {
    async logIn({ commit, dispatch }, payload) {
        try {
            const params = new URLSearchParams();
            params.append('username', payload.username);
            params.append('password', payload.password);

            commit("SET_FLAG_INCORRECT_LOGIN_DATA", false);

            const response = await API.post(`/login/`, params);

            if (response.status !== 200) {
                commit("SET_FLAG_INCORRECT_LOGIN_DATA", true);
                return;
            }

            const token = response.data.access_token;

            commit('SET_TOKEN', token);
            commit('SET_LOG_IN', true);

            await dispatch('getUserInfo');

            await router.push({ name: "event-view" })

        } catch (e) {
            console.log('Log in error', e);
        }
    },

    async checkLogIn(context) {
        if (!context.state.is_log_in) {

            let token = context.state.token;

            if (!token) {
                const localToken = getTokenLocal();

                if (localToken) {
                    context.commit('SET_TOKEN', localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    context.commit('SET_LOG_IN', true);
                } catch (error) {
                    context.commit('SET_USER_LOGOUT');
                }
            } else {
                context.commit('SET_USER_LOGOUT');
            }
        }
    },

    async getUserInfo(context) {
        const response = await UserAPI.userGetMe();

        context.commit('SET_USER_INFO', response.data);
    },

    async logOut(context) {
        await API.post(`/logout/`);

        removeTokenLocal();
        context.commit('SET_USER_LOGOUT');
    }
};

const mutations = {
    SET_TOKEN(state, token) {
        saveTokenLocal(token);
        state.token = token;
    },

    SET_LOG_IN(state, payload) {
        state.is_log_in = payload;
    },

    SET_USER_INFO(state, payload) {
        state.userInfo = {
            id: payload.id,
            login: payload.login,
            list_access: payload.list_access,
            is_super_user: payload.is_super_user,
        };
    },

    SET_USER_LOGOUT(state) {
        state.token = null;
        state.is_log_in = false;
        state.userInfo = {};
    },

    SET_FLAG_INCORRECT_LOGIN_DATA(state, value) {
        state.flag_incorrect_login_data = value;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
