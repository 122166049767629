import { EventAPI, EventTypeAPI } from "@/api/event.api";

const state = {
    is_event_loading: false,

    /** Массив событий для отображения. */
    event_list: [],

    event_child_list: [],

    /** Идентификатор выбранного события */
    event_selected_id: 0,

    /** Массив событий выдранных как "дочерние" события */
    event_sub_ids: [],

    /** Идентификатор текущего родителя  */
    event_parent_id: 0,

    /** Количество событий */
    event_count: 0,

    /** Чанки данных */
    event_chunk_limit: 100,
    event_chunk_offset: 0,

    event_type_list: [],

    event_modal: {
        id_event: 0,
        show: false,
    },
};

const getters = {
    isEventLoading: (state) => state.is_event_loading,

    eventList: (state) => state.event_list,

    eventChildList: (state) => state.event_child_list,

    eventAllList: (state) => {
        return [...state.event_list, ...state.event_child_list];
    },

    eventSubIds: (state) => state.event_sub_ids,
    eventParentId: (state) => state.event_parent_id,

    eventCount: (state) => state.event_count,

    eventSelectedId: (state) => state.event_selected_id,

    eventChunkLimit: (state) => state.event_chunk_limit,
    eventChunkOffset: (state) => state.event_chunk_offset,

    eventTypeList: (state) => state.event_type_list,
    eventTypeListActive: (state) =>
        state.event_type_list.filter((t) => t.active),

    eventModal: (state) => state.event_modal,
};

const actions = {
    async fetchEventList(context, { load_info }) {
        if (load_info && !context.getters.isEventLoading) {
            context.commit("SET_EVENT_LOADING", true);
        }

        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            offset: context.getters.eventChunkOffset, // infinite scroll
            limit: context.getters.eventChunkLimit, // infinite scroll
            load_info: load_info,
        };

        await EventAPI.eventGetAll(options).then((response) => {
            let payload = response.data;

            if (load_info) {
                context.commit("SET_EVENT_LIST", payload.data);
                context.commit("SET_EVENT_COUNT", payload.count);
                context.commit("base/SET_IS_DAY_CLOSED", payload.closed, {
                    root: true,
                });

                context.commit("SET_EVENT_LOADING", false);
            } else {
                context.commit("EVENT_LIST_APPEND", payload.data);
            }
        });
    },
    async fetchEventListPagination(context, params) {
        if (params.load_info && !context.getters.isEventLoading) {
            context.commit("SET_EVENT_LOADING", true);
        }
        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            offset: params.offset - 101,
            // params.offset > context.getters.eventCount
            //     ? context.getters.eventCount
            //     : params.offset,
            limit: params.limit,
            load_info: params.load_info,
        };
        await EventAPI.eventGetAll(options).then((response) => {
            let payload = response.data;

            if (params.load_info) {
                context.commit("SET_EVENT_LIST", payload.data);
                context.commit("SET_EVENT_COUNT", payload.count);
                context.commit("base/SET_IS_DAY_CLOSED", payload.closed, {
                    root: true,
                });

                context.commit("SET_EVENT_LOADING", false);
            } else {
                context.commit("EVENT_LIST_APPEND", payload.data);
            }
        });
    },

    async fetchEventListAndReturn(context) {
        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            offset: context.getters.eventChunkOffset,
            limit: context.getters.eventChunkLimit,
            load_info: true,
        };

        const response = await EventAPI.eventGetAll(options);

        let payload = response.data;

        return [payload.data, payload.count];
    },

    async fetchEventPeriod(context) {
        let options = await context.dispatch("base/baseOptionsPeriod", null, {
            root: true,
        });

        await EventAPI.periodGet(options).then((response) => {
            context.dispatch(
                "core/setDataTimePeriod",
                response.data.time_period,
                { root: true }
            );
        });
    },

    setEventList(context, payload) {
        context.commit("SET_EVENT_LIST", payload);
    },

    eventListAppend(context, payload) {
        context.commit("EVENT_LIST_APPEND", payload);
    },

    clearEventList(context) {
        context.commit("CLEAR_EVENT_LIST");
    },

    async fetchEventChildList(context, id_event) {
        const options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        await EventAPI.eventGetChildren(id_event, options).then((response) => {
            context.commit("SET_EVENT_PARENT_ID", id_event);
            context.commit("SET_EVENT_CHILD_LIST", {
                id_parent: id_event,
                payload: response.data,
            });
        });
    },

    clearEventChildList(context) {
        context.commit("CLEAR_EVENT_CHILD_LIST");
    },

    clearEventParentId(context) {
        context.commit("CLEAR_EVENT_PARENT_ID");
    },

    setEventSelected(context, value) {
        context.commit("SET_EVENT_SELECTED", value);
    },

    // Запрос к бекенду на обновление события
    async eventUpdate(context, { id_event, data }) {
        await EventAPI.eventUpdate(id_event, data).then((response) => {
            if (response.status === 403) {
                context.dispatch(
                    "core/addNotificationError",
                    {
                        header: "Закрытое событие",
                        message: "Вы не можете обновлять закрытые события.",
                        timeout: 3000,
                    },
                    { root: true }
                );
                return;
            }

            if (response.status !== 200) {
                context.dispatch(
                    "core/addNotificationError",
                    {
                        header: "Ошибка обновления события",
                        message:
                            "Произошла какая-то ошибка с обновлением события...",
                        timeout: 1250,
                    },
                    { root: true }
                );
            }

            context.commit("EVENT_UPDATE", {
                id_event: response.data.id,
                data: response.data,
            });
        });
    },

    async eventUpdateParent(context) {
        if (context.rootGetters["base/isDayClosed"]) {
            await context.dispatch(
                "core/addNotificationInfo",
                {
                    header: "Закрытое событие!",
                    message: "Вы не можете объединять закрытые события!",
                    timeout: 5000,
                },
                { root: true }
            );
            return;
        }

        const parent_id = context.getters.eventSelectedId;

        if (!parent_id) {
            await context.dispatch(
                "core/addNotificationInfo",
                {
                    header: "Невозможно объединить события!",
                    message: "Не выбрано главное событие",
                    timeout: 2500,
                },
                { root: true }
            );

            return;
        }

        const parent_item = context.getters.eventAllList.find(
            (t) => t.id === parent_id
        );

        if (parent_item.id_parent) {
            await context.dispatch(
                "core/addNotificationInfo",
                {
                    header: "Невозможно объединить события!",
                    message: "Невозможно сделать вложенное событие родителем.",
                    timeout: 10000,
                },
                { root: true }
            );
            return;
        }

        if (!context.getters.eventSubIds.length) {
            await context.dispatch(
                "core/addNotificationInfo",
                {
                    header: "Невозможно объединить события!",
                    message:
                        "Для объединения нужно минимум 2 выбранных события.\nЧтобы выбрать дополнительные событие - нажмите на него с зажатой клавишей Shift",
                    timeout: 10000,
                },
                { root: true }
            );
            return;
        }

        const sub_ids = context.getters.eventSubIds;
        for (let sub_id of sub_ids) {
            const data = {
                id_parent: parent_id,
            };
            await context.dispatch("eventUpdate", {
                id_event: sub_id,
                data: data,
            });
        }

        parent_item.has_child = true;

        // Обновление списка событий
        {
            const old_offset = context.getters.eventChunkOffset;
            const old_limit = context.getters.eventChunkLimit;

            await context.dispatch("setEventChunkOffset", 0);

            // :Note Лимит получаем только по списку родительских элементов, иначе
            // подзагрузка дополнительных событий не будет корректно в дальшейшем.
            await context.dispatch(
                "setEventChunkLimit",
                context.getters.eventList.length
            );

            const [data, count] = await context.dispatch(
                "fetchEventListAndReturn"
            );

            // Возвращаем старые значения для offset/limit
            await context.dispatch("setEventChunkOffset", old_offset);
            await context.dispatch("setEventChunkLimit", old_limit);

            await context.commit("SET_EVENT_COUNT", count);

            // Убираем старые события и добавляем новые
            const old_ids = context.getters.eventList.map((t) => t.id);
            const new_ids = data.map((t) => t.id);

            const ids_for_delete = old_ids.filter((t) => !new_ids.includes(t));
            const ids_for_append = new_ids.filter((t) => !old_ids.includes(t));

            const items_for_append = data.filter((t) =>
                ids_for_append.includes(t.id)
            );

            context.commit("EVENT_LIST_DELETE_ITEMS", ids_for_delete);
            context.commit("EVENT_LIST_APPEND", items_for_append);
        }

        context.commit("CLEAR_EVENT_CHILD_LIST");
        await context.dispatch("fetchEventChildList", parent_id);
    },

    eventUpdateApply(context, { id_event, data }) {
        context.commit("EVENT_UPDATE", {
            id_event: id_event,
            data: data,
        });
    },

    setEventLoading(context, value) {
        context.commit("SET_EVENT_LOADING", value);
    },

    // region EventType
    async fetchEventTypeList(context) {
        const response = await EventTypeAPI.eventTypeGetAll({ deleted: false });
        response.data.forEach((value) => (value.active = false));
        context.commit("SET_EVENT_TYPE_LIST", response.data);
    },

    setEventTypeList(context, payload) {
        context.commit("SET_EVENT_TYPE_LIST", payload);
    },
    // endregion

    showEventModal(context, id_event) {
        context.commit("SHOW_EVENT_MODAL", id_event);
    },

    closeEventModal(context) {
        context.commit("CLOSE_EVENT_MODAL");
    },

    setEventCount(context, value) {
        context.commit("SET_EVENT_COUNT", value);
    },

    setEventChunkOffset(context, value) {
        context.commit("SET_EVENT_CHUNK_OFFSET", value);
    },

    setEventChunkLimit(context, value) {
        context.commit("SET_EVENT_CHUNK_LIMIT", value);
    },

    async eventListSetState(context, id_state, extra_params) {
        let options = await context.dispatch("base/baseOptions", null, {
            root: true,
        });

        options = {
            ...options,
            id_state: id_state,
        };

        const response = await EventAPI.setGlobalState(options);

        if (response.status === 403) {
            await context.dispatch(
                "core/addNotificationError",
                {
                    header: "Закрытое событие",
                    message: "Вы не можете обновлять закрытые события.",
                    timeout: 3000,
                },
                { root: true }
            );
            return;
        }

        if (response.status !== 200) {
            await context.dispatch(
                "core/addNotificationError",
                {
                    header: "Ошибка обновления события",
                    message:
                        "Произошла какая-то ошибка с обновлением события...",
                    timeout: 2500,
                },
                { root: true }
            );
            return;
        }

        await context.dispatch("clearEventParentId");
        await context.dispatch("setEventSelected", 0);
        await context.dispatch(
            "setEventChunkOffset",
            (extra_params || {}).offset || 0
        ); // TODO: Нужно ли это в других плагинах?
        await context.dispatch("fetchEventList", { load_info: true });
    },

    eventSubAppend(context, value) {
        context.commit("EVENT_SUB_APPEND", value);
    },

    eventSubRemove(context, value) {
        context.commit("EVENT_SUB_REMOVE", value);
    },

    eventSubClear(context) {
        context.commit("EVENT_SUB_CLEAR");
    },

    clearEventsGlobal(context) {
        context.commit("CLEAR_EVENTS_GLOBAL");
    },
};

const mutations = {
    CLEAR_EVENTS_GLOBAL(state) {
        state.event_list = []; // CLEAR_EVENT_LIST
        state.event_sub_ids = []; // EVENT_SUB_CLEAR
        state.event_parent_id = 0; // CLEAR_EVENT_PARENT_ID
        state.event_selected_id = 0; // SET_EVENT_SELECTED
        state.event_count = 0; // SET_EVENT_COUNT
    },

    SET_EVENT_LIST(state, payload) {
        state.event_list = [...payload];
        state.event_list.sort((a, b) => a.begin_time - b.begin_time);

        state.event_selected_id =
            state.event_list.length > 0 ? state.event_list[0].id : 0;
    },

    EVENT_LIST_APPEND(state, payload) {
        payload.sort((a, b) => a.begin_time - b.begin_time);
        state.event_list.push(...payload);
        // state.event_list.sort((a, b) => (a.begin_time - b.begin_time));
    },

    EVENT_LIST_DELETE_ITEMS(state, id_list) {
        state.event_list = state.event_list.filter(
            (t) => !id_list.includes(t.id)
        );
    },

    CLEAR_EVENT_LIST(state) {
        state.event_list = [];
    },

    SET_EVENT_CHILD_LIST(state, { id_parent, payload }) {
        payload
            .sort((a, b) => a.begin_time - b.begin_time)
            .forEach((t) => (t.id_parent = id_parent));

        state.event_child_list = [...payload];
    },

    CLEAR_EVENT_CHILD_LIST(state) {
        state.event_child_list = [];
    },

    SET_EVENT_PARENT_ID(state, value) {
        state.event_parent_id = value;
    },

    CLEAR_EVENT_PARENT_ID(state) {
        state.event_parent_id = 0;
    },

    SET_EVENT_SELECTED(state, value) {
        state.event_selected_id = value;
    },

    EVENT_UPDATE(state, { id_event, data }) {
        let event = state.event_list.find((t) => t.id === id_event);

        if (!event) return;

        Object.entries(data).forEach(([key, value]) => {
            if (key === "id") return;

            if (event[key] !== value) {
                event[key] = value;
            }
        });
    },

    SET_EVENT_LOADING(state, value) {
        state.is_event_loading = value;
    },

    SET_EVENT_TYPE_LIST(state, payload) {
        state.event_type_list = payload;
    },

    SHOW_EVENT_MODAL(state, id_event) {
        state.event_modal.id_event = id_event;
        state.event_modal.show = true;
    },

    CLOSE_EVENT_MODAL(state) {
        state.event_modal.id_event = 0;
        state.event_modal.show = false;
    },

    SET_EVENT_COUNT(state, value) {
        state.event_count = value;
    },

    SET_EVENT_CHUNK_OFFSET(state, value) {
        state.event_chunk_offset = value;
    },

    SET_EVENT_CHUNK_LIMIT(state, value) {
        state.event_chunk_limit = value;
    },

    EVENT_SUB_APPEND(state, value) {
        state.event_sub_ids.push(value);
    },

    EVENT_SUB_REMOVE(state, value) {
        state.event_sub_ids.splice(state.event_sub_ids.indexOf(value), 1);
    },

    EVENT_SUB_CLEAR(state) {
        state.event_sub_ids = [];
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
