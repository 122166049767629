import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/MainView.vue'

import store from '@/store/index';

Vue.use(VueRouter);

function isLogIn(from, to, next) {
    // Check if we're not still logged in after the check
    if (!store.getters["auth/isLogIn"]) {
        console.log("User if not logged in. Redirect to login page.")
        next({name: 'login-page'});
        return;
    }
    next();
}

function hideFromLoggedIn(from, to, next) {
    if (store.getters["auth/isLogIn"]) {
        console.log("User is logged in. Redirect to main page.")
        next({name: 'event-view'});
        return;
    }

    next();
}

const routes = [
    {
        path: '/',
        meta: {
            layout: 'main'
        },
        component: Main,
        beforeEnter: isLogIn,
        children: [
            // Events
            {
                path: "/",
                name: "event-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/EventView.vue'),

            },

            // Auto
            {
                path: "/auto",
                name: "auto-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/AutoView.vue'),
            },

            // People
            {
                path: "/people",
                name: "people-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/PeopleView.vue'),
            },

            // Kko
            {
                path: "/kko",
                name: "kko-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/KkoView.vue'),
            },

            // Stoma
            {
                path: "/stoma",
                name: "stoma-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/StomaView.vue'),
            },

            // Ski
            {
                path: "/ski",
                name: "ski-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/SkiView.vue'),
            },

            // Shift
            {
                path: "/shift",
                name: "shift-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/ShiftView.vue'),
            },

            // Ozon
            {
                path: "/ozon",
                name: "ozon-view",
                meta: {
                    layout: 'main'
                },
                component: () => import('@/views/OzonView.vue'),
            }
        ]
    },
    {
        path: '/settings',
        name: 'settings-view',
        meta: {
            layout: 'main'
        },
        component: () => import('@/views/settings/SettingsView.vue'),
        beforeEnter: isLogIn,
    },
    {
        path: '/login',
        name: 'login-page',
        meta: {
            layout: 'empty'
        },
        component: () => import('@/views/LoginView.vue'),
        beforeEnter: hideFromLoggedIn
    },
    {
        path: '/logout',
        meta: {
            layout: 'empty'
        },
        component: () => import('../views/LoginView.vue'),
        beforeEnter: (from, to, next) => {
            store.dispatch('auth/logOut')
            next()
        }
    }
]


if (process.env.NODE_ENV !== 'production') {
    routes.push({
        path: "/dev",
        name: "dev-view",
        meta: {
            layout: 'empty'
        },
        component: () => import('@/views/dev/DevView.vue'),
    });
}

routes.push(
    {
        path: '*',
        redirect: {name: 'event-view'}
    }
)

const router = new VueRouter({
    mode: process.env.IS_ELECTRON ? 'hash' : 'history',
    base: process.env.BASE_URL,
    routes
});

/* eslist-disable */
router.beforeEach(async (to, from, next) => {
    // Check is use logged in
    if (!store.getters["auth/isLogIn"]) {
        console.log("Check log in")
        await store.dispatch("auth/checkLogIn")
    }

    if ((to.name === 'login-page') && (!store.getters["auth/isLogIn"])) {
        console.log("Redirect to login page")
        next();
        return;
    }

    // Check is logged in
    if (!store.getters["auth/isLogIn"]) {
        console.log("User not logged in. Redirect to login page")
        next({name: 'login-page'});
        return;
    }

    // get user info from the server if we don't already have it
    if (store.getters["auth/userInfo"].id === undefined) {
        console.log("Get user info")
        await store.dispatch("auth/getUserInfo");
    }

    next();
});
/* eslist-enable */

export default router
