import API, {APIReturn} from '@/api';
import {IEvent, IEventParams, IGlobalState, IPeriod, IPeriodParams as BasePeriodParams} from "@/api/event.api";

const resource = "/peoples";

interface IPeopleParams extends IEventParams {
    id_category: number[],
    id_department: number[],
    id_gender: number[],
    id_age: number[],
}

interface IPeopleItem {
    id: number
    id_master: number,

    first_name: string,
    middle_name: string,
    last_name: string,

    image: string,  // bytes?

    id_category: number,
    id_department: number,
    id_gender: number,
    id_age: number

    begin_time: number,
    end_time: number,
    companion: boolean
}

interface IPeopleUpdate {
    id: number // ???
    id_master: number,

    first_name: string,
    middle_name: string,
    last_name: string,

    id_category: number,
    id_department: number,
    id_gender: number,
    id_age: number
}

interface IPeopleList {
    count: number,
    closed: boolean,
    data: IPeopleItem[]
}

interface IPeriodParams extends BasePeriodParams {
    id_category: number,
    id_department: number,
    id_gender: number,
    id_age: number
}

interface IEventPeopleList {
    count: number,
    data: IEventPeopleItem
}

interface IEventPeopleAttr {
    id: number
    id_master: number,

    first_name: string,
    middle_name: string,
    last_name: string,

    id_category: number,
    id_department: number,
    id_gender: number,
    id_age: number
}

interface IEventPeopleItem extends IEvent {
    people: IEventPeopleAttr
}

interface IFindSimilar {
    id_camera: number,
    begin_time: string
}

interface IPersonCalendar {
    begin_time: string
}

interface ISeparateParams {
    id_event?: number[],
    id_image?: number
}

interface ICompanion {
    begin_time: string,
    end_time: string,
    value: boolean
}

// TODO:
// * Отсутствует
//   [PUT] /v1/peoples/{id_event}/events/
//   [DELETE] /v1/peoples/{id_event}/events/
export const PeopleAPI = {
    peopleGetAll: async (options: IPeopleParams): APIReturn<IPeopleList> => {
        // [GET] /v1/peoples/
        return await API.get<IPeopleList>(`${resource}/`, { params: options });
    },

    peopleGet: async (id: number): APIReturn<IPeopleItem> => {
        // [GET] /v1/peoples/:id/
        return await API.get<IPeopleItem>(`${resource}/${id}/`);
    },

    peopleUpdate: async (id: number, data: IPeopleUpdate): APIReturn<IPeopleItem> => {
        // [PUT] /v1/peoples/:id/
        return await API.put(`${resource}/${ id }/`, data);
    },

    peopleDelete: async (id: number): APIReturn<string> => {
        // [DELETE] /v1/peoples/:id/
        return await API.delete(`${resource}/${ id }/`);
    },

    periodGet: async (options: IPeriodParams): APIReturn<IPeriod> => {
        // [GET] /v1/people/period/
        return await API.get<IPeriod>(`${resource}/period/`, { params: options });
    },

    peopleCategoryGetAll: async (name: string): APIReturn<Array<IPeopleItem>> => {
        // [GET] /v1/peoples/category/:name/all/
        return await API.get<Array<IPeopleItem>>(`${resource}/category/${ name }/all/`);
    },

    peopleCategoryFindSimilar: async (name: string, id_people: number): APIReturn<Array<IPeopleItem>> => {
        // [GET] /v1/peoples/category/:name/:id_people/find_similar/
        return await API.get<Array<IPeopleItem>>(`${resource}/category/${ name }/${id_people}/find_similar/`);
    },

    findSimilar: async (id_people: number, options: IFindSimilar): APIReturn<Array<IPeopleItem>> => {
        // [GET] /v1/peoples/:id_people/find_similar
        return await API.get<Array<IPeopleItem>>(`${resource}/${ id_people }/find_similar/`,  { params: options });
    },

    personCalendar: async (id_people: number, options: IPersonCalendar): APIReturn<IPeriod> => {
        // [GET] /v1/peoples/:id_people/calendar/
        return await API.get<IPeriod>(`${resource}/${ id_people }/calendar/`, { params: options });
    },

    unionPeople: async (id_peoples: Array<number>): APIReturn<string> => {
        // [PUT] /v1/peoples/union/
        return await API.put<string>(`${resource}/union/`, id_peoples);
    },

    separateEvents: async (data: ISeparateParams): APIReturn<string> => {
        // [PUT] /v1/peoples/separation/
        return await API.put<string>(`${resource}/separation/`, data);
    },

    eventGetAll: async (id_people: number, options: IEventParams): APIReturn<IEventPeopleList> => {
        // [GET] /v1/peoples/:id_people/events/
        return await API.get<IEventPeopleList>(`${resource}/${ id_people }/events/`, { params: options });
    },

    eventSetGlobalState: async (id_people: number, options: IGlobalState): APIReturn<string> => {
        // [PUT] /v1/peoples/:id/events/set_state/
        return await API.put<string>(`${resource}/${ id_people }/events/set_state/`, null,{ params: options });
    },

    companionSet: async (id_people: number, options: ICompanion): APIReturn<IPeopleItem> => {
        // [PUT] /v1/peoples/:id_people/set_companion/
        return await API.put(`${resource}/${ id_people }/set_companion/`, null, { params: options });
    },
};

interface ISimpleItem {
    id: number,
    system_name: string,
    name: string
}

interface IPeopleAttr {
    system_name: string,
    value: string,
    type: string
}

export const PeopleAttrAPI = {
    categoryGetAll: async(): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/people/category/
        return await API.get<Array<ISimpleItem>>(`${resource}/category/`);
    },

    ageGetAll: async (): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/people/age/
        return await API.get<Array<ISimpleItem>>(`${resource}/age/`);
    },

    departmentGetAll: async (): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/people/department/
        return await API.get<Array<ISimpleItem>>(`${resource}/department/`);
    },

    genderGetAll: async (): APIReturn<Array<ISimpleItem>> => {
        // [GET] /v1/people/gender/
        return await API.get<Array<ISimpleItem>>(`${resource}/gender/`);
    },

    // TODO: get attr all
    peopleAttrGet: async (id_people: number): APIReturn<Array<IPeopleAttr>> => {
        // TODO: why attrs? in camera.api it's `attr`
        return await API.get<Array<IPeopleAttr>>(`${resource}/${id_people}/attrs/`);
    },

    peopleAttrUpdate: async (id_people: number, data: IPeopleAttr[]): APIReturn<any> => {
        return await API.put<any>(`${resource}/${id_people}/set_attr/`, data);
    },
}

interface IImage {
    id: number,
    image: string,
    manual: boolean,
    main_image: boolean
}

interface IImageUpdate {
    id: number
}

export const PeopleImageAPI = {
    imageGetAll: async (id_people: number): APIReturn<Array<IImage>> => {
        // [GET] /v1/peoples/:id_people/images/
        return await API.get<Array<IImage>>(`${resource}/${ id_people }/images/`);
    },

    imageUpdate: async (id_people: number, data: IImageUpdate): APIReturn<IPeopleItem> => {
        // [POST] /v1/peoples/:id_people/images/
        return await API.post<IPeopleItem>(`${resource}/${ id_people }/images/`, data);
    },

    imageDelete: async (id_people: number, id_image: number): APIReturn<IPeopleItem> => {
        // [DELETE] /v1/peoples/:id_peoples/images/:id_image/
        return await API.delete(`${resource}/${ id_people }/images/${id_image}/`);
    },

    imageUpload: async (id_people: number, data: FormData): APIReturn<IPeopleItem> => {
        // [DELETE] /v1/peoples/:id_peoples/images/upload/
        return await API.post(`${resource}/${ id_people }/images/upload/`, data);
    },


}
