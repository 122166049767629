export const MENU_LIST = [
    // Камеры
    { type: 'header', name: 'Камеры', is_visible: true},
    { type: 'item'  , name: 'Регистраторы',  view: () => import("@/views/settings/camera/SettingsRecorder.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Камеры',  view: () => import("@/views/settings/camera/SettingsCamera.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Справочники',  view: () => import("@/views/settings/camera/SettingsDictionary.vue"), visible: "admin", is_visible: true},

    // События
    { type: 'header', name: 'События', visible: "admin"},
    { type: 'item'  , name: 'Фильтры',  view: () => import("@/views/settings/event/SettingsFilter.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Статусы',  view: () => import("@/views/settings/event/SettingsStatus.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Сценарии',  view: () => import("@/views/settings/event/SettingsScenario.vue"), visible: "admin", is_visible: true},

    // Администратор
    { type: 'header', name: 'Администратор', visible: "admin"},
    { type: 'item'  , name: 'Атлас',  view: () => import("@/views/settings/admin/SettingsAtlas.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Система',  view: () => import("@/views/settings/admin/SettingsSystem.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Плагины',  view: () => import("@/views/settings/admin/SettingsPlugin.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Пользователи',  view: () => import("@/views/settings/admin/SettingsUsers.vue"), visible: "admin", is_visible: true},
    { type: 'item'  , name: 'Роли',  view: () => import("@/views/settings/admin/SettingsRoles.vue"), visible: "admin", is_visible: true},

]
