<template>
    <div id="app" data-app>
        <Update/>
        <BrowserSupport/>
        <div id="wrapper">
            <component :is="layout"></component>

            <router-view id="base" />
        </div>

        <ConfirmDialog/>
        <Notifications />
    </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

import ConfirmDialog from "@/components/core/ConfirmDialog.vue";
import Notifications from "@/components/core/Notifications.vue";
import Update from "@/components/core/Update.vue";

import SettingsView from "@/views/settings/SettingsView.vue";
import BrowserSupport from "@/components/core/BrowserSupport";
import { mapGetters } from "vuex";

export default {
    name: "App",

    components: {
        BrowserSupport,
        EmptyLayout,
        MainLayout,
        ConfirmDialog,
        Notifications,
        SettingsView,
        Update
    },

    computed: {
        ...mapGetters('base', ['pageTitle']),

        layout() {
            return (this.$route.meta.layout || "empty") + "-layout";
        },
    },

    watch: {
        pageTitle(value) {
            document.title = value;
        }
    },

    async mounted() {
        await this.fetchConfiguration();

        const vueApp = this;
        // Handler when internet is goes away...
        window.addEventListener("offline", function () {
            console.log("Internet is goes away.");
            vueApp.$store.dispatch("core/addNotificationError", {
                header: "Вы были отключены от сети!",
                message:
                    "Кажется, вы были отключены от сети!\nПожалуйста проверьте интернет соединение.",
                timeout: 15000
            });
        });

        // Handler when internet is online away...
        window.addEventListener("online", function () {
            console.log("Internet is came back.");
            vueApp.$store.dispatch("core/addNotificationInfo", {
                header: "Интернет вернулся!",
                message:
                    "Соединение с сетью было восстановлено!",
                timeout: 15000
            });
        });
    },

    methods: {
        async fetchConfiguration() {
            await this.$store.dispatch('base/fetchConfiguration');
        }
    }
};
</script>

<style>
</style>
